import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../Layout/Layout"
import Container from "../Layout/Container"

const AuthError = ({ location }) => {
  const locationState = location.state
  const title = locationState?.title || ""
  const seoTitle = locationState?.seoTitle || ""
  const message = locationState?.message || ""

  const data = useStaticQuery(graphql`
    {
      spilledMeds: file(relativePath: { eq: "pages/404__spilledMeds.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const spilledMeds = data.spilledMeds.childImageSharp.fluid

  return (
    <Layout seoTitle={seoTitle}>
      <Container customClassName="my-3" isCentered>
        <Container mobile={10} tablet={8} desktop={6} isCentered>
          <Img fluid={spilledMeds} />
        </Container>
        <center>
          <h2>{title}</h2>
          <p>{message}</p>
        </center>
      </Container>
    </Layout>
  )
}

export default AuthError
